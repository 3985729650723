import { SourceType } from "@/generated/requests/pos";
import { ImagesV2 } from "@/public/images/all";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import Text from "../../atoms/Text/Text";
import Button from "../Button/Button";
import ButtonLink from "../ButtonLink/ButtonLink";
import { DownloadTheAppButtons } from "../DownloadTheApp/DownloadTheAppButtons";

export default function ActionBoxes({ store }) {
  const { t } = useTranslation("stores");
  // const [sourceForStore] = useLazyPOSQuery(GenericSourceForStoreDocument);
  const router = useRouter();
  const { slug } = router?.query;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClickOrderNow = async () => {
    setIsLoading(true);
    try {
      // set source type to catering
      // const { data } = await sourceForStore({
      //   variables: {
      //     storeId: store?.storeId,
      //     type: SourceType.Catering
      //   }
      // });
      // const source = data?.public?.sourceForStore;
      // setSource(source);
      // since we are coming from a location page, skip the location selector with this query param
      router.push(`/order/${SourceType.Catering.toLowerCase()}?hasSelectedStore=${slug}`);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="px-4 lg:p-7 space-y-5 my-7 lg:grid grid-cols-2 lg:space-y-0 lg:gap-5 xl:grid-cols-3 max-w-8xl mx-auto">
      {/* join the crew */}
      <div className="rounded-[10px] bg-primary relative overflow-hidden">
        <Image src={ImagesV2.classicPinkSugar} alt="" className="absolute inset-0" />
        <div className="relative p-4 space-y-1 flex flex-col justify-between min-h-[400px] lg:min-h-[550px] lg:p-7">
          <div>
            <Text variant="display3" className="lg:text-5xl">
              {t("join_crew")}
            </Text>
            <Text variant="body2" className="lg:text-lg lg:mt-1">
              {t("join_crew_text")}
            </Text>
          </div>

          <ButtonLink href={`/jobs?s=${store?.name || ""}#positions`} variant="primary">
            {t("view_openings")}
          </ButtonLink>
        </div>
      </div>

      {/* catering */}
      <div className="rounded-[10px] bg-primary relative overflow-hidden">
        <Image src={ImagesV2.keyLimePie} alt="" className="absolute inset-0 -mt-10" />
        <div className="relative p-4 space-y-1 flex flex-col justify-between min-h-[400px] lg:min-h-[550px] lg:p-7">
          <div>
            <Text variant="display3" className="lg:text-5xl">
              {t("catering")}
            </Text>
            <Text variant="body2" className="lg:text-lg lg:mt-1">
              {t("catering_text", { storeName: store?.name })}
            </Text>
          </div>

          <Button onClick={handleClickOrderNow} variant="primary" disabled={isLoading}>
            {t("common:order_now")}
          </Button>
        </div>
      </div>

      {/* earn rewards */}
      <div className="rounded-[10px] bg-primary lg:col-span-2 xl:col-span-1">
        <div className="p-4 space-y-1 flex flex-col justify-between min-h-[400px] lg:min-h-[550px] lg:p-7">
          <div>
            <Text variant="display3" className="lg:text-5xl">
              {t("earn_rewards")}
            </Text>
            <Text variant="body2" className="lg:text-lg lg:mt-1">
              {t("earn_rewards_text")}
            </Text>
          </div>

          <div>
            <Image
              src={ImagesV2.illustrationCookiePillow}
              alt=""
              className="my-4 lg:max-h-[438px] lg:max-w-[499px] lg:mx-auto xl:max-w-full"
            />

            <DownloadTheAppButtons />
          </div>
        </div>
      </div>
    </div>
  );
}
